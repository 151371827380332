import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { initializeApp } from 'firebase/app'
import { getDatabase, ref, onValue } from 'firebase/database'
import { useTranslation } from 'react-i18next'
import { useFullscreen } from 'rooks'
import Div100vh from 'react-div-100vh'

import { LoadingScreen } from 'components'
import { COLORS } from 'theme'

import { GameCricket, GameCricketType, GameX01, GameX01Type } from './components'
import GameNotFound from './components/GameNotFound'

const firebaseConfig = {
  apiKey: 'AIzaSyBDTnebZNb6Bj_DXUAUJmZTDhM4w7h8GPM',
  appId: '1:653087419588:web:d2051e0c0387f37074ed91',
  authDomain: 'darts-scorer-180-267a7.firebaseapp.com',
  databaseURL: 'https://darts-scorer-180-267a7-default-rtdb.europe-west1.firebasedatabase.app',
  messagingSenderId: '653087419588',
  projectId: 'darts-scorer-180-267a7',
  storageBucket: 'darts-scorer-180-267a7.appspot.com'
}

const app = initializeApp(firebaseConfig)
const db = getDatabase(app)

type CommonGameProps = {
  headerLegLabel?: string
  headerRoundLabel: string
  headerSetLabel?: string
  status: 'active' | 'end' | 'waiting'
  type: 'cricket' | 'x01'
}

const Game = () => {
  const [gameCricket, setGameCricket] = useState<(GameX01Type & CommonGameProps) | undefined>()
  const [gameX01, setGameX01] = useState<(GameCricketType & CommonGameProps) | undefined>()
  const [init, setInit] = useState(false)

  const { id } = useParams()
  const reference = id?.length === 6 ? ref(db, `games/${id.toUpperCase()}`) : null

  const { t } = useTranslation()

  const fullscreen = useFullscreen()
  const request = fullscreen?.request
  const exit = fullscreen?.exit
  const isFullscreen = fullscreen?.isFullscreen
  const fullscreenSupported = fullscreen?.isEnabled
  const enabled = request && exit && fullscreenSupported

  useEffect(() => {
    if (reference) {
      onValue(reference, snapshot => {
        const data = snapshot.val()
        if (data?.type === 'cricket') {
          setGameCricket(data)
        } else {
          setGameX01(data)
        }
      })
    }

    const initTimer = setTimeout(() => {
      setInit(true)
    }, 10000)

    return () => clearTimeout(initTimer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // ! keep empty

  const game = gameCricket || gameX01

  if (!game) {
    if (!init) {
      return <LoadingScreen text={t('Searching for Game ...')} />
    } else {
      return <GameNotFound />
    }
  }

  const { headerLegLabel, headerRoundLabel, headerSetLabel, status, ...gameProps } = game

  return (
    <Div100vh>
      <Wrapper>
        <Header>
          {headerSetLabel && <HeaderLabel>{headerSetLabel}</HeaderLabel>}
          {headerLegLabel && <HeaderLabel>{headerLegLabel}</HeaderLabel>}
          <HeaderLabel>{headerRoundLabel}</HeaderLabel>

          <CodeSvgButton to="/">
            <CodeSvg viewBox="0 0 119 47">
              <path d="m27.585 23.14 3.57 1.925-2.03 3.36-3.675-2.205.105 4.025h-3.99l.105-4.025-3.675 2.205L16 25.065l3.57-1.925L16 21.18l1.995-3.36 3.675 2.24-.105-4.06h3.99l-.105 4.06 3.675-2.24 2.03 3.36-3.57 1.96ZM51.478 23.14l3.57 1.925-2.03 3.36-3.675-2.205.105 4.025h-3.99l.105-4.025-3.675 2.205-1.995-3.36 3.57-1.925-3.57-1.96 1.995-3.36 3.675 2.24-.105-4.06h3.99l-.105 4.06 3.675-2.24 2.03 3.36-3.57 1.96ZM75.37 23.14l3.57 1.925-2.03 3.36-3.674-2.205.105 4.025h-3.99l.105-4.025-3.675 2.205-1.995-3.36 3.57-1.925-3.57-1.96 1.995-3.36 3.675 2.24L69.35 16h3.99l-.105 4.06 3.675-2.24 2.03 3.36-3.57 1.96ZM99.264 23.14l3.57 1.925-2.03 3.36-3.675-2.205.105 4.025h-3.99l.105-4.025-3.675 2.205-1.995-3.36 3.57-1.925-3.57-1.96 1.995-3.36 3.675 2.24-.105-4.06h3.99l-.105 4.06 3.675-2.24 2.03 3.36-3.57 1.96Z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 4v38h111V4H4ZM2 0a2 2 0 0 0-2 2v42a2 2 0 0 0 2 2h115a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
              />
            </CodeSvg>
          </CodeSvgButton>

          {enabled && (
            <ScaleToggle onClick={() => (isFullscreen ? exit() : request())}>
              {isFullscreen ? (
                <ScaleToggleSvg $active={false} fill="none" viewBox="0 0 24 24">
                  <path
                    d="m22.414 3-6 6H21v2h-7a.998.998 0 0 1-1-.997V3h2v4.586l6-6L22.414 3ZM10 13H3v2h4.586l-6 6L3 22.414l6-6V21h2v-7a.997.997 0 0 0-.997-1h-.004Z"
                    fill={COLORS.TextOnSecondary}
                  />
                </ScaleToggleSvg>
              ) : (
                <ScaleToggleSvg $active fill="none" viewBox="0 0 21 21">
                  <path
                    d="M20.681.499a.999.999 0 0 1 .282.696v7h-2V3.61l-6 6-1.414-1.414 6-6h-4.586v-2h7a.997.997 0 0 1 .718.304ZM2.963 16.78v-4.585h-2v7a.997.997 0 0 0 1 1h7v-2H4.378l6-6-1.415-1.414-6 6Z"
                    fill={COLORS.TextOnSecondary}
                  />
                </ScaleToggleSvg>
              )}
            </ScaleToggle>
          )}
        </Header>

        {gameCricket ? (
          <GameCricket {...(gameProps as GameCricketType)} />
        ) : (
          <GameX01 {...(gameProps as GameX01Type)} />
        )}

        <Overlay $visible={status === 'end' || status === 'waiting'}>
          <OverlayChip>
            {status === 'end' ? t('Game finished') : t('Waiting for next Leg to begin...')}
          </OverlayChip>
        </Overlay>
      </Wrapper>
    </Div100vh>
  )
}

export default Game

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
`

const Header = styled.header`
  align-items: center;
  background-color: ${COLORS.Base};
  display: flex;
  height: 14%;
  padding: 0 calc(var(--vu) * 6);
  width: 100%;

  @media (orientation: portrait) {
    height: 10%;
  }
`

const HeaderLabel = styled.span`
  font-size: calc(var(--vu) * 3.25);
  font-weight: 800;
  margin-right: 1.5em;
  text-transform: uppercase;

  @media (orientation: portrait) {
    font-size: calc(var(--vu) * 3.75);
  }
`

const CodeSvgButton = styled(Link)`
  margin-left: auto;
`

const CodeSvg = styled.svg`
  height: calc(var(--vu) * 9);
  width: calc(var(--vu) * 9);

  path {
    fill: ${COLORS.TextOnBase};
  }

  @media (orientation: portrait) {
    height: calc(var(--vu) * 11);
    width: calc(var(--vu) * 11);
  }
`

const ScaleToggle = styled.button`
  margin-left: calc(var(--vu) * 5);
  padding: calc(var(--vu) * 1);
`

const ScaleToggleSvg = styled.svg<{ $active: boolean }>`
  height: calc(var(--vu) * 3.5);
  width: calc(var(--vu) * 3.5);

  path {
    fill: ${COLORS.TextOnBase};
  }
`

const Overlay = styled.div<{ $visible: boolean }>`
  background-color: rgba(255, 255, 255, 0.4);
  height: 100vh;
  left: 0;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.3s;
  width: 100%;
`

const OverlayChip = styled.span`
  background-color: #fff;
  border-radius: calc(var(--vu) * 0.25);
  top: 50%;
  font-size: calc(var(--vu) * 2.5);
  font-weight: 800;
  padding: calc(var(--vu)) calc(var(--vu) * 2);
  position: absolute;
  left: 50%;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%, -50%);

  @media (orientation: portrait) {
    font-size: calc(var(--vu) * 3);
  }
`
