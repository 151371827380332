import { FC } from 'react'
import styled from 'styled-components'
import { COLORS } from 'theme'

const Logo: FC = props => {
  return (
    <Svg fill="none" viewBox="0 0 118 53" {...props}>
      <path
        d="M107.703 23.442c-1.882 0-3.709-.225-5.481-.676-1.771-.45-3.21-1.052-4.318-1.803l2.159-4.701c1.041.665 2.248 1.202 3.621 1.61 1.373.407 2.723.611 4.052.611 2.525 0 3.787-.611 3.787-1.835 0-.644-.366-1.116-1.096-1.417-.709-.322-1.861-.655-3.455-.998-1.749-.365-3.211-.752-4.384-1.16a7.533 7.533 0 0 1-3.023-2.028c-.842-.923-1.262-2.168-1.262-3.735 0-1.374.387-2.609 1.162-3.703.775-1.117 1.927-1.997 3.455-2.641C104.47.322 106.363 0 108.6 0c1.528 0 3.033.172 4.517.515 1.484.322 2.79.805 3.92 1.45l-2.027 4.733c-2.214-1.16-4.362-1.74-6.444-1.74-1.306 0-2.258.194-2.856.58-.598.366-.897.849-.897 1.45 0 .6.354 1.052 1.063 1.352.709.3 1.849.612 3.421.934 1.772.365 3.233.762 4.385 1.191a7.26 7.26 0 0 1 3.023 1.997c.863.901 1.295 2.136 1.295 3.703a6.206 6.206 0 0 1-1.163 3.67c-.775 1.096-1.937 1.976-3.487 2.641-1.55.644-3.433.966-5.647.966Z"
        fill="#111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.012.45H1.017v22.541h10.995c2.546 0 4.805-.461 6.776-1.384 1.97-.923 3.499-2.233 4.584-3.929C24.457 15.982 25 13.997 25 11.721c0-2.275-.543-4.261-1.628-5.957-1.085-1.696-2.613-3.005-4.584-3.929-1.97-.923-4.23-1.384-6.776-1.384Zm4.517 15.812c-1.195 1.094-2.79 1.642-4.783 1.642H7.594V5.539h4.152c1.993 0 3.588.547 4.783 1.642 1.218 1.095 1.827 2.608 1.827 4.54s-.609 3.446-1.827 4.54ZM32.055 22.991l1.827-4.379h9.832l1.827 4.38h6.843L42.085.45H35.61L25.345 22.99h6.71Zm6.743-16.293 2.99 7.213h-5.98l2.99-7.213ZM64.412 17.002h-3.587v5.99h-6.577V.45h10.63c2.103 0 3.93.343 5.48 1.03 1.55.666 2.746 1.632 3.587 2.898.842 1.245 1.263 2.716 1.263 4.412 0 1.631-.399 3.059-1.196 4.283-.775 1.202-1.893 2.146-3.355 2.833l5.016 7.084H68.63l-4.219-5.989Zm4.152-8.211c0-1.052-.343-1.868-1.03-2.447-.686-.58-1.704-.87-3.055-.87h-3.654v6.601h3.654c1.35 0 2.37-.279 3.056-.837.686-.58 1.03-1.395 1.03-2.447Z"
        fill="#111"
      />
      <path
        d="M76.32 5.506h7.141v17.485h6.577V5.506h7.108V.451H76.32v5.055ZM7.347 53c-1.47 0-2.882-.25-4.239-.752-1.337-.5-2.373-1.162-3.108-1.984l.82-1.713c.715.761 1.667 1.383 2.854 1.864 1.186.46 2.41.691 3.673.691 1.77 0 3.1-.34 3.984-1.022.886-.701 1.329-1.603 1.329-2.706 0-.841-.245-1.513-.735-2.014a4.547 4.547 0 0 0-1.752-1.142c-.697-.28-1.667-.581-2.91-.902-1.489-.4-2.676-.781-3.561-1.142a6.117 6.117 0 0 1-2.29-1.714c-.62-.761-.932-1.793-.932-3.096 0-1.062.264-2.024.792-2.886.527-.882 1.337-1.583 2.43-2.104 1.092-.521 2.449-.782 4.069-.782 1.13 0 2.232.17 3.306.511 1.093.32 2.035.772 2.826 1.353l-.707 1.774a9.153 9.153 0 0 0-2.656-1.293 9.075 9.075 0 0 0-2.769-.451c-1.733 0-3.042.36-3.928 1.082-.866.702-1.3 1.614-1.3 2.736 0 .842.236 1.523.707 2.044.49.501 1.092.892 1.808 1.172.735.261 1.715.552 2.939.872 1.45.381 2.619.762 3.504 1.143a5.58 5.58 0 0 1 2.289 1.683c.622.742.932 1.754.932 3.036a5.429 5.429 0 0 1-.819 2.916c-.527.862-1.347 1.553-2.459 2.075-1.111.5-2.477.751-4.097.751ZM27.869 53c-1.96 0-3.73-.46-5.313-1.383-1.563-.922-2.797-2.194-3.701-3.818-.886-1.643-1.328-3.477-1.328-5.5 0-2.025.442-3.849 1.328-5.472.904-1.643 2.147-2.926 3.73-3.848 1.582-.922 3.353-1.383 5.312-1.383 1.47 0 2.826.26 4.07.782a8.433 8.433 0 0 1 3.164 2.284l-1.328 1.413c-1.544-1.663-3.494-2.495-5.85-2.495-1.563 0-2.985.381-4.266 1.143a8.201 8.201 0 0 0-3.024 3.126c-.716 1.323-1.074 2.806-1.074 4.45 0 1.642.358 3.126 1.074 4.448a8.202 8.202 0 0 0 3.024 3.127c1.28.761 2.703 1.142 4.267 1.142 2.373 0 4.323-.842 5.85-2.525l1.327 1.413a8.678 8.678 0 0 1-3.193 2.314c-1.243.521-2.6.782-4.069.782Z"
        fill="#111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m71.866 46.056 4.521 6.764h2.29l-4.833-7.215c1.45-.501 2.562-1.313 3.334-2.435.792-1.142 1.187-2.535 1.187-4.179 0-2.244-.716-4.008-2.147-5.29-1.432-1.283-3.41-1.925-5.934-1.925H62.88V52.82h2.09v-6.674h5.314c.546 0 1.073-.03 1.582-.09Zm2.854-3.187c-1.036.922-2.534 1.383-4.493 1.383H64.97V33.7h5.256c1.96 0 3.457.461 4.493 1.383 1.036.902 1.554 2.205 1.554 3.908 0 1.664-.518 2.956-1.554 3.878Z"
        fill="#111"
      />
      <path
        d="M97.288 52.82v-1.924H85.392V43.08h10.23v-1.894h-10.23V33.7h11.473v-1.924H83.3V52.82h13.987Z"
        fill="#111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m111.19 46.056 4.521 6.764H118l-4.832-7.215c1.45-.501 2.562-1.313 3.334-2.435.792-1.142 1.187-2.535 1.187-4.179 0-2.244-.716-4.008-2.147-5.29-1.432-1.283-3.41-1.925-5.935-1.925h-7.403V52.82h2.091v-6.674h5.312c.547 0 1.074-.03 1.583-.09Zm2.854-3.187c-1.036.922-2.534 1.383-4.493 1.383h-5.256V33.7h5.256c1.959 0 3.457.461 4.493 1.383 1.036.902 1.554 2.205 1.554 3.908 0 1.664-.518 2.956-1.554 3.878Z"
        fill="#111"
      />
      <path
        d="M46.73 39.763c-.3.081-.523.23-.667.446-.146.207-.18.455-.104.744.074.28.222.479.445.595.229.106.498.117.808.033.31-.084.537-.23.683-.437.143-.216.178-.464.104-.744-.076-.289-.229-.486-.458-.592-.23-.114-.501-.13-.81-.045ZM48.204 45.33c.39-.107.675-.286.853-.537a1.02 1.02 0 0 0 .145-.888c-.088-.33-.274-.558-.56-.683-.281-.136-.617-.151-1.008-.045-.39.106-.678.29-.862.552-.178.251-.223.542-.136.872.087.33.271.563.552.699.286.125.625.135 1.016.03Z"
        fill="#111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.88 36.484a3.41 3.41 0 0 0-1.238-.683c-.664-.2-1.359-.201-2.084-.005-.733.2-1.337.553-1.814 1.063-.476.509-.786 1.15-.931 1.923-.139.762-.085 1.61.162 2.541.246.932.62 1.697 1.118 2.295.505.588 1.089.982 1.753 1.182.664.2 1.363.2 2.096.002.724-.197 1.325-.55 1.801-1.06.244-.26.443-.553.598-.879-.42 5.643-5.076 10.09-10.757 10.09-5.872 0-10.648-4.749-10.786-10.66l.065.246 1.589-.43 1.79 6.766 2.42-.656-2.292-8.66-3.47.942c.734-5.309 5.238-9.394 10.684-9.394 3.958 0 7.42 2.159 9.296 5.377Zm-6.853 5.759a2.668 2.668 0 0 0-1.13-.608c.239-.286.396-.598.473-.937a2.1 2.1 0 0 0-.018-1.056 2.202 2.202 0 0 0-.778-1.208c-.388-.31-.87-.497-1.445-.562-.568-.067-1.186-.01-1.854.171-.667.181-1.235.446-1.701.794-.46.345-.78.75-.962 1.215-.185.457-.21.936-.078 1.44.097.362.261.671.495.926.241.253.536.442.883.569-.33.337-.556.703-.678 1.099a2.226 2.226 0 0 0-.01 1.249c.144.544.426.98.844 1.309.419.328.939.527 1.56.598.62.07 1.298.005 2.03-.194.734-.198 1.352-.485 1.855-.86.503-.376.855-.811 1.055-1.308.2-.496.229-1.016.085-1.56a2.205 2.205 0 0 0-.626-1.077Z"
        fill="#111"
      />
      <path
        d="M56.411 42.798c.407-.11.677-.396.808-.856.138-.47.092-1.139-.137-2.004-.23-.866-.519-1.464-.868-1.794-.343-.34-.718-.454-1.125-.344-.416.113-.692.404-.83.875-.131.46-.082 1.123.147 1.989.23.865.515 1.468.858 1.808.35.33.732.438 1.147.326Z"
        fill="#111"
      />
    </Svg>
  )
}

export default Logo

const Svg = styled.svg`
  height: auto;
  opacity: 0.4;
  width: calc(var(--vu) * 40);

  path {
    fill: ${COLORS.TextOnSecondary};
  }

  @media (orientation: portrait) {
    height: auto;
    width: calc(var(--vu) * 28);
  }
`
