import { useState } from 'react'
import styled, { css } from 'styled-components'
import useWindowOrientation from 'use-window-orientation'
import { useNavigate } from 'react-router-dom'
import Div100vh from 'react-div-100vh'

import { Logo, LogoHorizontal } from 'components'
import { COLORS } from 'theme'

const Home = () => {
  const navigate = useNavigate()
  const [input, setInput] = useState('')
  const { portrait: isPortrait } = useWindowOrientation()

  return (
    <Div100vh>
      <Wrapper>
        <Header>{isPortrait && <Logo />}</Header>

        <Form
          onSubmit={e => {
            e.preventDefault()
            navigate(`/${input}`)
          }}
        >
          <TextInput
            onChange={e => setInput(e.target.value)}
            placeholder="ABC123"
            size={9}
            value={input}
          />
          <Submit>
            <svg fill="none" viewBox="0 0 10 21">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m4.646.464 4.815 10.143-4.828 9.857-4.31-2.11 3.81-7.78L.312 2.523 4.646.464Z"
              />
            </svg>
          </Submit>
        </Form>

        <Footer>
          <Copyright>&copy; Appitized GmbH</Copyright>
          {!isPortrait && <LogoHorizontal />}
          <FooterLink href="https://www.appitized.io/imprint" target="_blank">
            Impressum
          </FooterLink>
        </Footer>
      </Wrapper>
    </Div100vh>
  )
}

export default Home

const Wrapper = styled.div`
  align-items: center;
  background-color: ${COLORS.Secondary};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

const Header = styled.header`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

const Form = styled.form`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.075);
  border-radius: calc(var(--vu) * 6);
  display: flex;
  height: calc(var(--vu) * 12);
  justify-content: space-between;
  padding-left: calc(var(--vu) * 6);

  @media (orientation: portrait) {
    border-radius: calc(var(--vu) * 7);
    height: calc(var(--vu) * 14);
  }
`

const TextInput = styled.input`
  background-color: transparent;
  border: none;
  color: ${COLORS.TextOnSecondary};
  flex: 1;
  font-size: calc(var(--vu) * 7);
  font-weight: 800;
  height: 100%;
  letter-spacing: calc(var(--vu) * 0.75);
  outline: none;
  text-transform: uppercase;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.1);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.1);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.1);
  }

  @media (orientation: portrait) {
    font-size: calc(var(--vu) * 9);
  }
`

const Submit = styled.button`
  align-items: center;
  background-color: ${COLORS.Primary};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: calc(var(--vu) * 10);
  justify-content: center;
  margin-right: calc(var(--vu) * 1);
  width: calc(var(--vu) * 10);

  svg {
    height: 40%;
    width: 40%;

    path {
      fill: ${COLORS.Secondary};
    }
  }

  @media (orientation: portrait) {
    height: calc(var(--vu) * 12);
    width: calc(var(--vu) * 12);
  }
`

const Footer = styled.footer`
  align-items: flex-end;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 calc(var(--vu) * 4) calc(var(--vu) * 4);
  width: 100%;
`

const sharedFooterStyles = css`
  color: ${COLORS.TextOnSecondary};
  font-size: calc(var(--vu) * 1.5);
  opacity: 0.4;
`

const Copyright = styled.span`
  ${sharedFooterStyles};
`

const FooterLink = styled.a`
  ${sharedFooterStyles};
  &:hover {
    opacity: 0.9;
    text-decoration: underline;
  }
`
