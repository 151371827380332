import { FC } from 'react'
import styled from 'styled-components'
import { COLORS } from 'theme'

const Logo: FC = props => {
  return (
    <Svg fill="none" viewBox="0 0 239 24" {...props}>
      <path
        d="M101.205 22.766c1.771.45 3.598.676 5.481.676 2.214 0 4.096-.322 5.646-.966 1.551-.665 2.713-1.545 3.488-2.64a6.206 6.206 0 0 0 1.163-3.671c0-1.567-.432-2.802-1.296-3.703a7.25 7.25 0 0 0-3.022-1.997c-1.152-.429-2.613-.826-4.385-1.191-1.572-.322-2.713-.633-3.421-.934-.709-.3-1.063-.751-1.063-1.352 0-.601.299-1.084.897-1.45.598-.386 1.55-.58 2.856-.58 2.082 0 4.23.58 6.444 1.74l2.026-4.734C114.89 1.32 113.584.837 112.1.515A19.971 19.971 0 0 0 107.582 0c-2.236 0-4.129.322-5.68.966-1.528.644-2.679 1.524-3.454 2.64-.775 1.095-1.163 2.33-1.163 3.704 0 1.567.421 2.812 1.263 3.735.841.923 1.849 1.6 3.022 2.029 1.174.408 2.635.794 4.385 1.159 1.594.343 2.746.676 3.454.998.731.3 1.097.773 1.097 1.417 0 1.224-1.263 1.835-3.787 1.835-1.329 0-2.68-.204-4.053-.611-1.373-.408-2.579-.945-3.62-1.61l-2.16 4.7c1.108.752 2.547 1.353 4.319 1.804Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 .45h10.995c2.546 0 4.805.462 6.776 1.385 1.97.924 3.499 2.233 4.584 3.929 1.085 1.696 1.627 3.682 1.627 5.957 0 2.276-.542 4.261-1.627 5.957-1.085 1.696-2.613 3.006-4.584 3.929-1.971.923-4.23 1.384-6.776 1.384H0V.451Zm10.729 17.454c1.993 0 3.587-.548 4.783-1.642 1.218-1.095 1.827-2.609 1.827-4.54 0-1.933-.609-3.446-1.827-4.541-1.196-1.095-2.79-1.642-4.783-1.642H6.577v12.365h4.152ZM31.038 22.991l1.827-4.379h9.832l1.827 4.38h6.842L41.07.45h-6.477L24.328 22.99h6.71ZM37.78 6.698l2.99 7.213h-5.98l2.99-7.213ZM59.808 17.002h3.587l4.219 5.99h7.041l-5.015-7.085c1.461-.687 2.58-1.631 3.355-2.833.797-1.224 1.195-2.652 1.195-4.283 0-1.696-.42-3.167-1.262-4.412-.841-1.266-2.037-2.232-3.587-2.898-1.55-.687-3.377-1.03-5.48-1.03H53.23v22.54h6.577v-5.989Zm6.71-10.658c.686.58 1.03 1.395 1.03 2.447s-.344 1.867-1.03 2.447c-.687.558-1.706.837-3.056.837h-3.654v-6.6h3.654c1.35 0 2.369.289 3.055.869Z"
        fill="#000"
      />
      <path
        d="M75.302 5.506h7.142v17.485h6.577V5.506h7.108V.451H75.302v5.055ZM124.091 21.142c1.357.5 2.769.751 4.239.751 1.62 0 2.986-.25 4.097-.751 1.112-.521 1.931-1.213 2.459-2.075a5.43 5.43 0 0 0 .819-2.916c0-1.282-.311-2.294-.932-3.036a5.584 5.584 0 0 0-2.289-1.683c-.886-.381-2.054-.762-3.504-1.143-1.225-.32-2.204-.61-2.939-.871a5.085 5.085 0 0 1-1.809-1.173c-.471-.52-.706-1.202-.706-2.044 0-1.122.433-2.034 1.3-2.736.885-.721 2.195-1.082 3.928-1.082.904 0 1.827.15 2.769.451.942.28 1.827.712 2.656 1.293l.707-1.774c-.792-.581-1.733-1.032-2.826-1.353a10.87 10.87 0 0 0-3.306-.51c-1.62 0-2.977.26-4.069.781-1.093.521-1.903 1.222-2.431 2.104-.527.862-.791 1.824-.791 2.886 0 1.303.311 2.335.933 3.097a6.11 6.11 0 0 0 2.289 1.713c.885.36 2.072.742 3.56 1.142 1.243.321 2.214.622 2.911.902.697.26 1.281.642 1.752 1.143.489.5.734 1.172.734 2.014 0 1.102-.442 2.004-1.328 2.705-.885.682-2.213 1.022-3.984 1.022-1.262 0-2.487-.23-3.674-.691-1.187-.481-2.138-1.102-2.854-1.864l-.819 1.714c.734.821 1.771 1.483 3.108 1.984ZM148.852 21.893c-1.959 0-3.73-.46-5.313-1.383-1.563-.922-2.797-2.194-3.702-3.817-.885-1.644-1.328-3.478-1.328-5.502 0-2.024.443-3.848 1.328-5.47.905-1.644 2.148-2.927 3.731-3.849C145.15.95 146.921.49 148.88.49c1.469 0 2.826.26 4.069.782a8.424 8.424 0 0 1 3.165 2.285l-1.328 1.413c-1.545-1.664-3.495-2.496-5.849-2.496-1.564 0-2.986.381-4.267 1.143a8.205 8.205 0 0 0-3.024 3.126c-.716 1.323-1.074 2.806-1.074 4.45 0 1.643.358 3.126 1.074 4.448a8.205 8.205 0 0 0 3.024 3.127c1.281.761 2.703 1.142 4.267 1.142 2.373 0 4.323-.842 5.849-2.525l1.328 1.413a8.674 8.674 0 0 1-3.193 2.315c-1.243.52-2.6.781-4.069.781Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m197.37 21.713-4.521-6.764c-.509.06-1.036.09-1.583.09h-5.312v6.674h-2.091V.67h7.403c2.525 0 4.503.641 5.934 1.924 1.432 1.282 2.148 3.046 2.148 5.29 0 1.644-.396 3.037-1.187 4.18-.772 1.121-1.884 1.933-3.334 2.434l4.832 7.215h-2.289Zm-6.16-8.568c1.959 0 3.457-.46 4.493-1.383 1.036-.921 1.554-2.214 1.554-3.877 0-1.704-.518-3.007-1.554-3.908-1.036-.922-2.534-1.383-4.493-1.383h-5.256v10.551h5.256Z"
        fill="#000"
      />
      <path
        d="M218.271 21.713v-1.924h-11.896v-7.816h10.229v-1.894h-10.229V2.594h11.472V.67h-13.564v21.043h13.988Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m236.694 21.713-4.521-6.764c-.509.06-1.037.09-1.583.09h-5.312v6.674h-2.091V.67h7.403c2.525 0 4.503.641 5.934 1.924 1.432 1.282 2.148 3.046 2.148 5.29 0 1.644-.396 3.037-1.187 4.18-.772 1.121-1.884 1.933-3.334 2.434l4.832 7.215h-2.289Zm-6.16-8.568c1.959 0 3.456-.46 4.493-1.383 1.036-.921 1.554-2.214 1.554-3.877 0-1.704-.518-3.007-1.554-3.908-1.037-.922-2.534-1.383-4.493-1.383h-5.256v10.551h5.256Z"
        fill="#000"
      />
      <path
        d="M167.713 8.656c-.301.082-.524.23-.667.446-.146.208-.181.456-.105.744.075.28.223.48.446.595.229.106.498.117.807.033.31-.084.538-.23.684-.437.143-.216.178-.464.104-.744-.077-.289-.229-.486-.458-.592-.231-.114-.501-.129-.811-.045ZM170.04 13.686c-.178.252-.463.43-.854.536-.391.106-.729.097-1.016-.029-.28-.136-.464-.369-.551-.699-.088-.33-.042-.62.136-.872.183-.262.471-.446.862-.552.391-.106.726-.091 1.007.045.286.126.473.353.56.683.088.33.039.626-.144.888Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.863 5.377a3.413 3.413 0 0 0-1.239-.683c-.664-.2-1.358-.201-2.083-.005-.733.2-1.338.553-1.814 1.063-.476.509-.787 1.15-.931 1.923-.139.762-.085 1.61.161 2.541.247.932.62 1.697 1.119 2.296.504.587 1.089.981 1.753 1.181.664.2 1.362.2 2.095.002.725-.197 1.326-.55 1.802-1.06.244-.26.443-.553.598-.879-.421 5.643-5.076 10.09-10.758 10.09-5.871 0-10.647-4.749-10.785-10.66l.065.247 1.588-.431 1.791 6.766 2.419-.656-2.291-8.659-3.47.941C159.617 4.085 164.12 0 169.566 0c3.959 0 7.42 2.159 9.297 5.377Zm-6.853 5.76a2.665 2.665 0 0 0-1.13-.609c.239-.286.396-.598.472-.937a2.091 2.091 0 0 0-.017-1.056 2.204 2.204 0 0 0-.778-1.207c-.388-.31-.87-.498-1.445-.563-.568-.067-1.186-.01-1.854.172-.668.18-1.235.445-1.702.793-.458.345-.779.75-.961 1.215a2.224 2.224 0 0 0-.078 1.44c.096.363.261.671.494.926.242.253.536.443.884.57a2.71 2.71 0 0 0-.678 1.098 2.226 2.226 0 0 0-.01 1.249c.144.544.425.98.844 1.309.419.328.939.527 1.559.598.621.07 1.298.006 2.031-.193.734-.2 1.352-.486 1.855-.861.503-.375.855-.811 1.055-1.307.2-.497.228-1.017.084-1.561a2.201 2.201 0 0 0-.625-1.077Z"
        fill="#000"
      />
      <path
        d="M178.202 10.835c-.131.46-.401.745-.808.856-.415.112-.798.004-1.147-.325-.343-.34-.629-.943-.858-1.81-.229-.865-.278-1.528-.147-1.988.138-.47.414-.762.829-.875.408-.11.783.005 1.126.345.349.33.638.927.867 1.793.23.866.276 1.534.138 2.004Z"
        fill="#000"
      />
    </Svg>
  )
}

export default Logo

const Svg = styled.svg`
  height: auto;
  opacity: 0.25;
  width: calc(var(--vu) * 30);

  path {
    fill: ${COLORS.TextOnSecondary};
  }
`
