import { FC } from 'react'
import styled from 'styled-components'
import { COLORS } from 'theme'
import Loader from './DartLoader'

const LoadingScreen: FC<{ text?: string }> = ({ text }) => {
  return (
    <Wrapper>
      <Loader />
      {text && <Text>{text}</Text>}
    </Wrapper>
  )
}

export default LoadingScreen

const Wrapper = styled.div`
  align-items: center;
  background-color: ${COLORS.Secondary};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

const Text = styled.span`
  color: ${COLORS.TextOnSecondary};
  font-size: calc(var(--vu) * 2);
  font-weight: 700;
  margin-top: 6vh;
  text-transform: uppercase;

  @media (orientation: portrait) {
    font-size: calc(var(--vu) * 3);
    margin-top: 4vh;
  }
`
