import { createGlobalStyle } from 'styled-components'

const Style = createGlobalStyle`
  :root {
    --vu: calc(0.5vw + 0.5vh);

    @media (orientation: portrait) {
      --vu: calc(0.8vw + 0.2vh);
    }
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    width: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-weight: 700;
  }

  a, button, p, span, b, i, li, label {
    color: #10161d;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    line-height: 1.1;
  }

  span {
    color: #10161d;
    line-height: 1.1;
  }

  a, button, svg {
    transition: .25s;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  button {
    border: none;
    background: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  ul, li {
    margin: 0;
    padding: 0;
  }

  input {
    font-family: Montserrat, Helvetica, Arial, sans-serif;
  }
`

export default Style
