import React, { useEffect } from 'react'
import useSmoothScroll from 'react-smooth-scroll-hook'
import useWindowOrientation from 'use-window-orientation'

const useScrollTo = ({
  containerRef,
  CPI,
  directionLandscape,
  directionPortrait,
  idKey = 'player',
  numOfPlayers,
  playersInViewport
}: {
  containerRef: React.RefObject<HTMLDivElement>
  CPI: number
  directionLandscape: 'x' | 'y'
  directionPortrait: 'x' | 'y'
  idKey?: string
  numOfPlayers: number
  playersInViewport: number
}) => {
  const { portrait: isPortrait } = useWindowOrientation()
  const { reachedBottom: reachedEnd, scrollTo } = useSmoothScroll({
    ref: containerRef,
    speed: 20,
    direction: isPortrait ? directionPortrait : directionLandscape
  })

  useEffect(() => {
    if (numOfPlayers > playersInViewport) {
      if (CPI === 0 && reachedEnd) {
        scrollTo(`#${idKey}-0`)
      } else if (CPI >= playersInViewport) {
        scrollTo(`#${idKey}-${CPI}`)
      }
    }
  }, [CPI, idKey, numOfPlayers, playersInViewport, reachedEnd, scrollTo])
}

export default useScrollTo
