import { FC, useRef } from 'react'
import styled, { css } from 'styled-components'

import { useScrollTo } from 'hooks'
import { COLORS } from 'theme'

export type GameCricketType = {
  CPI: number
  players: Array<{
    mpr?: number
    name: string
    score: number
    scores: Array<{ label: string; hits: number }>
  }>
}

const PLAYERS_LIMIT = 4

const GameCricket: FC<GameCricketType> = ({ CPI, players }) => {
  const ref = useRef<HTMLDivElement>(null)

  const playersInViewport = players.length > PLAYERS_LIMIT ? PLAYERS_LIMIT : players.length
  const multiplier =
    {
      1: 1,
      2: 0.95,
      3: 0.75
    }[playersInViewport] ?? 1

  useScrollTo({
    containerRef: ref,
    CPI,
    directionLandscape: 'y',
    directionPortrait: 'x',
    numOfPlayers: players.length,
    playersInViewport
  })

  return (
    <Wrapper ref={ref}>
      {players.map(({ mpr, name, score, scores }, i) => {
        return (
          <Player
            $active={CPI === i}
            $hasBorder={playersInViewport > 2}
            $isFirst={i === 0}
            $isLast={i === players.length - 1}
            $multiplier={multiplier}
            $numOfPlayers={players.length}
            $size={100 / playersInViewport}
            id={`player-${i}`}
            key={`player-${name}-${i}`}
          >
            <Header>
              <Name>{name}</Name>
              <MPR>{`MPR: ${mpr}`}</MPR>
            </Header>

            <Hits>
              {scores.map(({ hits, label }) => {
                return (
                  <Target>
                    <TargetLabel $opacity={!hits ? 0.25 : hits === 3 ? 0.1 : 0.175}>
                      {label}
                    </TargetLabel>
                    {hits === 1 && (
                      <HitsIcon viewBox="0 0 128 128">
                        <path
                          d="M125 12l-9.899-9.9L1.963 115.239l9.9 9.899L125 12z"
                          fill={COLORS.TextOnBase}
                        />
                      </HitsIcon>
                    )}
                    {hits === 2 && (
                      <HitsIcon viewBox="0 0 128 128">
                        <path
                          d="M115.1 2.1L125 12 73.4 63.6l51.637 51.638-9.9 9.899L63.5 73.5l-51.637 51.637-9.9-9.899L53.6 63.6 2 12l9.9-9.9 51.6 51.601 51.6-51.6z"
                          fill={COLORS.TextOnBase}
                        />
                      </HitsIcon>
                    )}
                    {hits >= 3 && (
                      <HitsIcon viewBox="0 0 128 128">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M125 12l-9.9-9.9-14.416 14.417A59.74 59.74 0 0064 4a59.745 59.745 0 00-37.245 12.956L11.9 2.101 2 12l14.867 14.867A59.744 59.744 0 004 64a59.74 59.74 0 0012.517 36.684L1.963 115.238l9.9 9.899 14.448-14.448A59.752 59.752 0 0064 124a59.744 59.744 0 0037.132-12.867l14.005 14.004 9.9-9.899-13.993-13.993A59.745 59.745 0 00124 64a59.747 59.747 0 00-13.312-37.689L125 12zM90.679 26.522A45.79 45.79 0 0064 18a45.794 45.794 0 00-27.258 8.943L63.5 53.7l27.179-27.179zM26.857 36.858A45.793 45.793 0 0018 64a45.79 45.79 0 008.522 26.679L53.6 63.6 26.857 36.858zm9.427 63.858A45.795 45.795 0 0064 110a45.795 45.795 0 0027.142-8.857L63.5 73.5l-27.216 27.216zm64.773-9.457L73.4 63.6l27.316-27.316A45.795 45.795 0 01110 64a45.794 45.794 0 01-8.943 27.258z"
                          fill={COLORS.TextOnBase}
                        />
                      </HitsIcon>
                    )}
                  </Target>
                )
              })}
            </Hits>

            <Footer>
              <Score>{score}</Score>
            </Footer>
          </Player>
        )
      })}
    </Wrapper>
  )
}

export default GameCricket

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (orientation: portrait) {
    flex-direction: row;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
  }
`

const Header = styled.div`
  align-items: center;
  background-color: ${COLORS.Secondary};
  color: ${COLORS.TextOnSecondary};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 1%;
  width: 17vw;

  @media (orientation: portrait) {
    height: 10vh;
    width: 100%;
  }
`

const Name = styled.span`
  color: inherit;
  font-size: 0.175em;
  font-size: calc(var(--vu) * 2.5);
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (orientation: portrait) {
    font-size: calc(var(--vu) * 3);
    text-overflow: initial;
    white-space: normal;
    word-break: break-all;
  }
`

const MPR = styled.span`
  color: inherit;
  font-size: calc(var(--vu) * 2.25);
  font-weight: 400;
  margin-top: 0.2em;
  opacity: 0.67;
  text-align: center;
  text-transform: uppercase;

  @media (orientation: portrait) {
    font-size: calc(var(--vu) * 2.75);
  }
`

const Hits = styled.div`
  background-color: #e8e8e8;
  display: flex;
  flex: 1;
  height: 100%;
  padding: 0 2%;

  @media (orientation: portrait) {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
`

const Target = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
`

const sharedLabelStyles = css`
  font-size: 0.2em;
  font-size: calc(var(--vu) * 3.125);
  font-weight: 800;
  text-align: center;
`

const TargetLabel = styled.span<{ $opacity: number }>`
  ${sharedLabelStyles};
  color: ${COLORS.TextOnBase};
  flex: 1;
  opacity: ${({ $opacity }) => $opacity};

  @media (orientation: portrait) {
    font-size: calc(var(--vu) * 3.5);
  }
`

const HitsIcon = styled.svg`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 0.25em;
  width: 0.25em;
  height: calc(var(--vu) * 4);
  width: calc(var(--vu) * 4);

  @media (orientation: portrait) {
    height: calc(var(--vu) * 4.75);
    width: calc(var(--vu) * 4.75);
  }
`

const Footer = styled.div`
  align-items: center;
  background-color: ${COLORS.Secondary};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 13vw;

  @media (orientation: portrait) {
    height: 10vh;
    width: 100%;
  }
`

const Score = styled.span`
  ${sharedLabelStyles};
  color: ${COLORS.TextOnSecondary};

  @media (orientation: portrait) {
    font-size: 0.25em;
    font-size: calc(var(--vu) * 4);
  }
`

const Player = styled.div<{
  $active: boolean
  $hasBorder: boolean
  $isFirst: boolean
  $isLast: boolean
  $multiplier: number
  $numOfPlayers: number
  $size: number
}>`
  align-items: center;
  border-top: ${({ $hasBorder, $isFirst }) =>
    $hasBorder && !$isFirst ? `calc(var(--vu) * 0.25) solid ${COLORS.Base}` : '0'};
  border-bottom: ${({ $hasBorder, $isLast }) =>
    $hasBorder && !$isLast ? `calc(var(--vu) * 0.25) solid ${COLORS.Base}` : '0'};
  border-right: none;
  border-left: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-size: ${({ $multiplier }) => `calc(var(--vu) * 18 * ${$multiplier})`};
  height: ${({ $size }) => `${$size}%`};
  justify-content: center;

  ${({ $active }) =>
    $active &&
    css`
      ${Header} {
        background-color: ${COLORS.Primary};
        color: ${COLORS.TextOnPrimary};
      }
      ${Hits} {
        background-color: #f2f2f2;
      }
      ${Footer} {
        background-color: ${COLORS.Primary};
      }
      ${Score} {
        color: ${COLORS.TextOnPrimary};
      }
    `};

  @media (orientation: portrait) {
    border-left: ${({ $hasBorder, $isFirst }) =>
      $hasBorder && !$isFirst ? `calc(var(--vu) * 0.3) solid ${COLORS.Base}` : '0'};
    border-right: ${({ $hasBorder, $isLast }) =>
      $hasBorder && !$isLast ? `calc(var(--vu) * 0.3) solid ${COLORS.Base}` : '0'};
    border-top: none;
    border-bottom: none;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    flex-basis: ${({ $numOfPlayers: num }) =>
      `${num === 1 ? '100vw' : num === 2 ? '50vw' : num === 3 ? '33.333vw' : '25vw'}`};
  }
`
