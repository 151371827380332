import { FC } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { COLORS } from 'theme'

const Loader: FC<{ bullColor?: string; color?: string; sizeScale?: number }> = ({
  bullColor,
  color,
  sizeScale,
  ...props
}) => {
  const outerScale = sizeScale || 19

  return (
    <Wrapper $color={color || 'rgba(255, 255, 255, 0.33)'} $scale={outerScale} {...props}>
      <Outer />
      <Mid />
      <Inner $color={bullColor || COLORS.Primary} />
    </Wrapper>
  )
}

export default Loader

const rotate = keyframes`
  from {
      transform: rotate(0deg);
      transform-origin: center;
  }
  to {
      transform: rotate(360deg);
      transform-origin: center;
  }
`

const sharedStyles = css`
  border-radius: 50%;
  left: 0;
  position: absolute;
  top: 0;
`

const animationStyles = css`
  animation-name: ${rotate};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  border-style: solid;
`

const Outer = styled.div`
  ${animationStyles};
  ${sharedStyles};

  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  height: 100%;
  width: 100%;
`

const Mid = styled.div`
  ${animationStyles};
  ${sharedStyles};

  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  height: 60%;
  left: 20%;
  width: 60%;
  top: 20%;
`

const Inner = styled.div<{ $color: string }>`
  ${sharedStyles};

  background-color: ${({ $color }) => $color};
  height: 18%;
  left: 41%;
  top: 41%;
  width: 18%;
`

const Wrapper = styled.div<{ $color: string; $scale: number }>`
  height: ${({ $scale }) => `calc(var(--vu) * ${$scale})`};
  position: relative;
  width: ${({ $scale }) => `calc(var(--vu) * ${$scale})`};

  ${Outer} {
    border-color: ${({ $color }) => $color};
    border-right-color: transparent;
    border-width: ${({ $scale }) => `calc(var(--vu) * ${$scale * 0.09375})`};
  }

  ${Mid} {
    border-color: ${({ $color }) => $color};
    border-right-color: transparent;
    border-width: ${({ $scale }) => `calc(var(--vu) * ${$scale * 0.09375})`};
  }
`
