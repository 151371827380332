import { FC, useRef } from 'react'
import styled, { css } from 'styled-components'
import useWindowOrientation from 'use-window-orientation'

import { useScrollTo } from 'hooks'
import { COLORS } from 'theme'

export type GameX01Type = {
  bust?: boolean
  CPI: number
  players: Array<{
    avg?: number
    name: string
    score: number
    subline?: string
  }>
}

const GameX01: FC<GameX01Type> = ({ bust, CPI, players }) => {
  const ref = useRef<HTMLDivElement>(null)

  const { portrait: isPortrait } = useWindowOrientation()

  const playersLimit = isPortrait ? 3 : 4
  const playersInViewport = players.length > playersLimit ? playersLimit : players.length
  const multiplier =
    {
      1: 1,
      2: 0.95,
      3: 0.75,
      4: 0.6
    }[playersInViewport] ?? 1

  useScrollTo({
    containerRef: ref,
    CPI,
    directionLandscape: 'x',
    directionPortrait: 'y',
    numOfPlayers: players.length,
    playersInViewport
  })

  return (
    <Wrapper ref={ref}>
      {players.map(({ avg, name, score, subline }, i) => {
        return (
          <Player
            $active={CPI === i}
            $bust={Boolean(bust) && CPI === i}
            $hasBorder={playersInViewport > 2}
            $isFirst={i === 0}
            $isLast={i === players.length - 1}
            $multiplier={multiplier}
            $numOfPlayers={players.length}
            $size={100 / playersInViewport}
            id={`player-${i}`}
            key={`player-${name}-${i}`}
          >
            <PlayerContent>
              <Score>{score}</Score>
              <Name>{name}</Name>
              {subline && <Subline>{subline}</Subline>}
            </PlayerContent>
            {(avg || avg === 0) && <Avg>{avg.toFixed(1)}</Avg>}
          </Player>
        )
      })}
    </Wrapper>
  )
}

export default GameX01

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (orientation: portrait) {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  }
`

const PlayerContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

const Score = styled.span`
  color: ${COLORS.TextOnPrimary};
  font-size: 1em;
  font-weight: 800;
  margin-bottom: 0.15em;
  margin-top: -0.2em;
  text-align: center;
`

const Name = styled.span`
  color: ${COLORS.TextOnPrimary};
  font-size: 0.25em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
`

const Subline = styled.span`
  color: ${COLORS.TextOnPrimary};
  font-size: 0.2em;
  font-weight: 400;
  margin-top: 0.3em;
  text-transform: uppercase;
  text-align: center;
`

const Avg = styled.span`
  background-color: rgba(0, 0, 0, 0.1);
  color: ${COLORS.TextOnPrimary};
  font-size: 0.25em;
  font-weight: 800;
  margin-top: 0.3em;
  padding: 0.75em 0;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
`

const Player = styled.div<{
  $active: boolean
  $bust: boolean
  $hasBorder: boolean
  $isFirst: boolean
  $isLast: boolean
  $multiplier: number
  $numOfPlayers: number
  $size: number
}>`
  align-items: center;
  background-color: ${({ $active, $bust }) =>
    $bust ? '#eb4d4b' : $active ? COLORS.Primary : COLORS.Secondary};
  border-left: ${({ $hasBorder, $isFirst }) =>
    $hasBorder && !$isFirst ? `calc(var(--vu) * 0.25) solid ${COLORS.Base}` : '0'};
  border-right: ${({ $hasBorder, $isLast }) =>
    $hasBorder && !$isLast ? `calc(var(--vu) * 0.25) solid ${COLORS.Base}` : '0'};
  border-bottom: none;
  border-top: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: ${({ $multiplier }) => `calc(var(--vu) * 18 * ${$multiplier})`};
  justify-content: center;
  padding-top: calc(var(--vu) * 4);
  width: ${({ $size }) => `${$size}%`};

  ${({ $active }) =>
    !$active &&
    css`
      ${Name} {
        color: ${COLORS.TextOnSecondary};
      }
      ${Score} {
        color: ${COLORS.TextOnSecondary};
        font-weight: 700;
      }
      ${Subline} {
        color: ${COLORS.TextOnSecondary};
      }
      ${Avg} {
        color: ${COLORS.TextOnSecondary};
      }
    `};

  @media (orientation: portrait) {
    border-top: ${({ $hasBorder, $isFirst }) =>
      $hasBorder && !$isFirst ? `calc(var(--vu) * 0.3) solid ${COLORS.Base}` : '0'};
    border-bottom: ${({ $hasBorder, $isLast }) =>
      $hasBorder && !$isLast ? `calc(var(--vu) * 0.3) solid ${COLORS.Base}` : '0'};
    border-left: none;
    border-right: none;
    height: ${({ $size }) => `${$size}%`};
    width: 100%;
  }
`
