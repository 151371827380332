import { Routes, Route } from 'react-router-dom'
import { Game, Home } from 'pages'
import AppStyles from './AppStyles'

const App = () => {
  return (
    <>
      <AppStyles />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<Game />} />
      </Routes>
    </>
  )
}

export default App
