import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'theme'

const GameNotFound: FC = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Heading>{t('Game not found')}</Heading>

      <Icon fill="none" viewBox="0 0 128 128">
        <path
          d="M116 64c0 28.719-23.281 52-52 52S12 92.719 12 64s23.281-52 52-52 52 23.281 52 52Z"
          fill="#fff"
          fillOpacity=".05"
        />
        <path
          d="M60.55 33.636c1.354-2.181 4.546-2.181 5.9 0l28.028 45.11C95.904 81.041 94.242 84 91.527 84H35.473c-2.715 0-4.377-2.959-2.95-5.254l28.026-45.11Z"
          fill="#45C5A9"
        />
        <path
          d="M66.248 66.34c-.023.469-.374.837-.799.837h-3.898c-.425 0-.776-.368-.8-.838l-.75-15.406c-.024-.507.34-.933.8-.933H66.2c.459 0 .823.426.799.933l-.75 15.406Zm-6.227 7.192c0-1.102.296-1.955.886-2.56.591-.618 1.45-.926 2.577-.926 1.115 0 1.958.308 2.526.926.58.605.87 1.458.87 2.56 0 1.09-.3 1.943-.902 2.56-.591.605-1.422.908-2.494.908-1.083 0-1.931-.303-2.544-.908-.612-.617-.919-1.47-.919-2.56Z"
          fill="#2F3E50"
        />
        <path
          d="m102.184 11.168-2.07-2.07 1.491-1.49 2.069 2.069 2.042-2.041 1.496 1.496-2.041 2.041 2.069 2.07-1.491 1.49-2.069-2.07-2.008 2.009-1.496-1.496 2.008-2.008Z"
          fill="#C1C5CB"
          fillOpacity=".7"
        />
        <path
          d="m117.709 12.136-1.882-1.883 1.356-1.356 1.882 1.883 1.857-1.858 1.362 1.362-1.857 1.857 1.882 1.882-1.356 1.356-1.883-1.882-1.826 1.827-1.362-1.361 1.827-1.827Z"
          fill="#C1C5CB"
          fillOpacity=".5"
        />
        <path
          d="m113.211 25.158-2.645-2.644 1.905-1.905 2.645 2.644 2.608-2.609 1.913 1.913-2.609 2.608 2.644 2.645-1.905 1.905-2.644-2.645-2.566 2.567-1.913-1.913 2.567-2.566Z"
          fill="#C1C5CB"
        />
        <path
          d="M8.547 102.247 6.24 99.939l1.663-1.662 2.308 2.307 2.276-2.276 1.669 1.668-2.277 2.277 2.308 2.308-1.662 1.662-2.308-2.307-2.24 2.239-1.668-1.669 2.24-2.239Z"
          fill="#C1C5CB"
          fillOpacity=".67"
        />
        <path
          d="m18.327 116.318-2.704-2.703 1.948-1.948 2.703 2.703 2.667-2.667 1.955 1.955-2.667 2.667 2.704 2.704-1.948 1.947-2.703-2.703-2.624 2.623-1.955-1.955 2.624-2.623Z"
          fill="#C1C5CB"
        />
      </Icon>

      <Subline>
        {t('Please check your code and')} <Anchor to="/">{t('try again')}</Anchor>
      </Subline>
    </Wrapper>
  )
}

export default GameNotFound

const Wrapper = styled.div`
  align-items: center;
  background-color: ${COLORS.Secondary};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

const Heading = styled.h1`
  color: ${COLORS.TextOnSecondary};
  font-size: calc(var(--vu) * 4.5);
  font-weight: 700;
  text-transform: uppercase;

  @media (orientation: portrait) {
    font-size: calc(var(--vu) * 6);
  }
`

const Icon = styled.svg`
  height: 40vh;
  margin: 5vh 0;
  width: auto;
`

const Subline = styled.p`
  color: ${COLORS.TextOnSecondary};
  font-size: calc(var(--vu) * 3);

  @media (orientation: portrait) {
    font-size: calc(var(--vu) * 3.5);
  }
`

const Anchor = styled(Link)`
  color: inherit;
  font-size: inherit;
  font-weight: 700;
  text-decoration: underline;
`
